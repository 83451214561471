import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RightArrow from "./arrows/RightArrow";
import LeftArrow from "./arrows/LeftArrow";

export default function SliderMain(props) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: "progressive",
    draggable: false,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
  };
  return (
    <div className="slider">
      <Slider {...settings}>
        {props.images.map((slide) => (
          <div className="w-slide" key={Math.random()}>
            <div
              className="slider-image"
              style={{ backgroundImage: `url(${slide.img})` }}
            ></div>
            {slide.title !== "" && (
              <p className="over-text">
                <strong>
                  {props.lang === "RO" ? slide.title : slide.title_en}
                </strong>
                 - {props.lang === "RO" ? slide.text : slide.text_en}
              </p>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
}
