import React from "react";
import { useState, useEffect } from "react";
import SliderSmall from "./SliderSmall";
import { Link } from "react-router-dom";
import Markdown from "markdown-to-jsx";

export default function OfferTop(props) {
  const [offerData, setOfferData] = useState({});
  useEffect(() => {
    setOfferData(props.offerData);
  }, [props]);
  console.log(offerData);
  return (
    <div className="section-standard">
      <div className="container-std first">
        <h1 id="testelement" className="room-title">
          {props.lang === "RO"
            ? props.offerData.titlu
            : props.offerData.titlu_en}
        </h1>
        <div className="top-button-holder room">
          <Link
            className="button-oferta w-button"
            style={{ minWidth: 300, marginTop: 10 }}
            to={
              props.lang === "RO"
                ? `/oferte/rezervari/${offerData.offerId}`
                : `/en/offers/reservations/${offerData.offerId}`
            }
          >
            {props.lang === "RO" ? "rezervă acum" : "new reservation"}
          </Link>
        </div>
        <p className="paragraph-standard">
          <strong>{props.lang === "RO" ? "Perioada" : "Period"}:</strong> 
          {props.lang === "RO"
            ? props.offerData.perioada
            : props.offerData.perioada_en}
        </p>
        <p className="paragraph-standard">
          <strong>
            {props.lang === "RO" ? "Durata sejurului" : "Duration"}:{" "}
          </strong>
          {props.lang === "RO"
            ? props.offerData.durata
            : props.offerData.durata_en}
        </p>
        <p className="paragraph-standard">
          <strong>
            {props.lang === "RO" ? "Tipul sejurului" : "Type of package"}:{" "}
          </strong>
          {props.lang === "RO" ? props.offerData.tip : props.offerData.tip_en}
        </p>
        {offerData &&
          offerData.preturi &&
          offerData.preturi.map((item) => (
            <p className="paragraph-standard">
              <strong>
                {props.lang === "RO" ? item.camera : item.camera_en}:{" "}
              </strong>
              {item.pret}
            </p>
          ))}

        {offerData &&
          offerData.text &&
          props.lang === "RO" &&
          offerData.text.map((item) => (
            <p className="paragraph-standard">
              <Markdown>{item}</Markdown>
            </p>
          ))}

        {offerData &&
          offerData.text_en &&
          props.lang === "EN" &&
          offerData.text_en.map((item) => (
            <p className="paragraph-standard">
              <Markdown>{item}</Markdown>
            </p>
          ))}

        {offerData && offerData.foto && <SliderSmall images={offerData.foto} />}
      </div>
    </div>
  );
}
