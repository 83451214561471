import React from "react";
import FacilitatiGrid from "./FacilitatiGrid";

export default function Despre(props) {
  return (
    <div className="section-standard">
      <div className="container-std">
        <h1 className="section-title">
          {props.lang === "RO"
            ? `Despre ${props.data.header.nume}`
            : `About ${props.data.header.nume_en}`}
        </h1>
        <p className="paragraph-standard">
          {props.lang === "RO" ? props.data.despre.p1 : props.data.despre.p1_en}
        </p>
        <p className="paragraph-standard">
          {props.lang === "RO" ? props.data.despre.p2 : props.data.despre.p2_en}
        </p>
        <FacilitatiGrid lang={props.lang} fac={props.data.facilitati} />

        <p className="paragraph-standard">
          {props.lang === "RO" ? props.data.despre.p3 : props.data.despre.p3_en}
        </p>
      </div>
    </div>
  );
}
