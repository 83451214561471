import React from "react";
import Next from "./right-white.svg";

export default function RightArrow(props) {
  return (
    <div>
      <img
        src={Next}
        onClick={props.onClick}
        className="slick-next"
        alt="right arrow"
        style={{ filter: "drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.5))" }}
      />
    </div>
  );
}
