import React from "react";
import Slider from "react-slick";
import RightArrow from "./arrows/RightArrow"
import LeftArrow from "./arrows/LeftArrow"

export default function SliderSmall(props) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: false,
    draggable: false,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />
  };
  return (
    <div className="slider-room content-page">
      <Slider {...settings}>
        {props.images.map(slide => (
          <div className="w-slide" key={Math.random()}>
            <div
              className="image-container"
              style={{ backgroundImage: `url(${slide})` }}
            ></div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
