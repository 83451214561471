import React from "react";
import { useState, useEffect } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import theme from "../theme.js";
import { db } from "../lib/db";

export default function ResOfferTop(props) {
  const [offerData, setOfferData] = useState({});
  useEffect(() => {
    setOfferData(props.offerData);
  }, [props]);
  console.log(offerData);

  const [camera, setCamera] = useState("");
  const [cameraError, setCameraError] = useState(false);
  const [nume, setNume] = useState("");
  const [numeError, setNumeError] = useState(false);
  const [persoane, setPersoane] = useState("");
  const [persoaneError, setPersoaneError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [mentiuni, setMentiuni] = useState("");
  const [politici, setPolitici] = useState(false);
  const [politiciError, setPoliticiError] = useState(false);
  const [hotelName, setHotelName] = useState("");

  const handleSubmit = async () => {
    nume.length > 0 ? setNumeError(false) : setNumeError(true);
    persoane.length > 0 ? setPersoaneError(false) : setPersoaneError(true);
    phone.length > 0 ? setPhoneError(false) : setPhoneError(true);
    camera.length > 0 ? setCameraError(false) : setCameraError(true);
    politici === true ? setPoliticiError(false) : setPoliticiError(true);
    if (
      nume.length > 0 &&
      persoane.length > 0 &&
      phone.length > 0 &&
      camera.length > 0 &&
      politici === true
    ) {
      let obj = {};
      obj.nume = nume;
      obj.persoane = persoane;
      obj.phone = phone;
      obj.email = email;
      obj.camera = offerData.preturi.find(
        (itm) => itm.codCamera === camera
      ).camera;
      obj.inDate = offerData.perioada;
      obj.outDate = offerData.titlu;
      obj.emailHotel = props.emailHotel;
      obj.hotel = props.hotelName;
      obj.mentiuni = mentiuni;

      await db
        .collection("rezervari")
        .add(obj)
        .then(() =>
          window.location.assign(
            props.lang === "RO" ? "/confirmare" : "/en/confirmation"
          )
        );
    }
  };
  return (
    <div className="section-standard">
      <div className="container-std first">
        <h1 className="room-title">
          {props.lang === "RO" ? "Rezervare nouă" : "New reservation"}
        </h1>
        <p className="paragraph-standard">
          {props.lang === "RO"
            ? "Vă rugăm completați formularul de mai jos pentru a rezerva această ofertă specială. Câmpurile marcate cu un asterisc (*) sunt obligatorii."
            : "Please fill in the form below to book this special offer. Fields marked with an aseterisk (*) are required."}
        </p>

        <div className="card horizontal">
          {offerData && offerData.foto && (
            <div
              className="hz-image"
              style={{ backgroundImage: `url(${offerData.foto[0]})` }}
            ></div>
          )}

          <div className="card-content-holder">
            <p className="card-title">
              {offerData && offerData.titlu && offerData.titlu}
            </p>
            <p className="paragraph-standard">
              <strong>{props.lang === "RO" ? "Perioada" : "Period"}:</strong> 
              {offerData.perioada}
            </p>
            <p className="paragraph-standard">
              <strong>
                {props.lang === "RO" ? "Durata sejurului" : "Duration"}:
              </strong>
               {offerData.durata}
            </p>
            <p className="paragraph-standard">
              <strong>
                {props.lang === "RO" ? "Tipul sejurului" : "Type of package"}:
              </strong>
               {offerData.tip}
            </p>
          </div>
        </div>
        <MuiThemeProvider theme={theme}>
          <FormControl
            fullWidth
            style={{ marginBottom: 10 }}
            variant="outlined"
          >
            <InputLabel id="camera">
              {props.lang === "RO" ? "Tip de cameră" : "Room type"}
            </InputLabel>
            <Select
              error={cameraError}
              labelId="format-hartie-label"
              id="format-hartie"
              label="Tip de cameră"
              value={camera}
              onChange={(event) => setCamera(event.target.value)}
            >
              {offerData &&
                offerData.preturi &&
                offerData.preturi.map((item) => (
                  <MenuItem value={item.codCamera}>
                    {item.camera} - {item.pret}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            id="name"
            required
            type="text"
            error={numeError}
            fullWidth
            label={props.lang === "RO" ? "Nume Complet" : "Full name"}
            value={nume}
            onChange={(event) => setNume(event.target.value)}
            variant="outlined"
            style={{ marginTop: 20, marginBottom: 10 }}
            // helperText="Câmp necesar."
          />
          <TextField
            id="nr-pers"
            type="number"
            error={persoaneError}
            required
            fullWidth
            label={props.lang === "RO" ? "Nr. oaspeți" : "Number of guests"}
            value={persoane}
            onChange={(event) => setPersoane(event.target.value)}
            variant="outlined"
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <TextField
            id="phone"
            type="text"
            error={phoneError}
            required
            fullWidth
            label={props.lang === "RO" ? "Telefon" : "Phone"}
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
            variant="outlined"
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <TextField
            id="email"
            type="email"
            fullWidth
            label="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            variant="outlined"
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <TextField
            id="mentiuni"
            type="text"
            fullWidth
            label={
              props.lang === "RO" ? "Mențiuni speciale" : "Other instructions"
            }
            value={mentiuni}
            onChange={(event) => setMentiuni(event.target.value)}
            variant="outlined"
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <p className="paragraph-standard" style={{ marginTop: 20 }}>
            <strong>
              {props.lang === "RO"
                ? "Politici de rezervare"
                : "Reservation policies"}
            </strong>
          </p>

          {props.lang === "RO" ? (
            <p className="paragraph-standard" style={{ marginTop: 0 }}>
              {" "}
              Vă rugăm citiți{" "}
              <a target="_blank" href="/politici-rezervare/">
                <u>politicile de rezervare</u>
              </a>{" "}
              înainte de a trimite acest formular.
            </p>
          ) : (
            <p className="paragraph-standard" style={{ marginTop: 0 }}>
              Please read the{" "}
              <a target="_blank" href="/en/reservation-policies/">
                <u>reservation policies</u>
              </a>{" "}
              before submitting this form.
            </p>
          )}

          <FormControl error={politiciError}>
            <FormControlLabel
              style={{ marginTop: 0, marginBottom: 0 }}
              control={
                <Checkbox
                  checked={politici}
                  onChange={() => setPolitici(!politici)}
                  value="politici"
                  color="primary"
                />
              }
              label={
                props.lang === "RO"
                  ? "Sunt de acord cu politicile de rezervare"
                  : "I accept the reservation policies"
              }
            />
            {politiciError && (
              <FormHelperText>
                {props.lang === "RO" ? "Câmp necesar" : "Required field"}
              </FormHelperText>
            )}
          </FormControl>
        </MuiThemeProvider>
        <p className="paragraph-standard" style={{ marginTop: 20 }}>
          <strong>
            {props.lang === "RO"
              ? "Procesarea datelor personale"
              : "Personal data processing notice"}
          </strong>
        </p>
        <p className="paragraph-standard" style={{ marginBottom: 20 }}>
          {props.lang === "RO" ? props.gdpr : props.gdpr}
        </p>
        <div
          className="button-inline w-button"
          style={{ marginTop: 10, padding: 15, marginRight: 0 }}
          onClick={handleSubmit}
        >
          {props.lang === "RO" ? "Trimite" : "Submit"}
        </div>
      </div>
    </div>
  );
}
