import React from "react";
import Prev from "./left-white.svg";

export default function LeftArrow(props) {
  return (
    <div>
      <img
        src={Prev}
        onClick={props.onClick}
        className="slick-prev"
        alt="right arrow"
        style={{ filter: "drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4))" }}
      />
    </div>
  );
}
