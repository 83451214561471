import React from "react";
import { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../theme.js";
import { db } from "../lib/db";
import { useHistory } from "react-router-dom";
import Dates from "./Dates";
import data from "../data/data.json";
import { Divider } from "@material-ui/core";

export default function ResRoomTop(props) {
  console.log(props);
  let history = useHistory();
  const [roomData, setRoomData] = useState({});
  const [facilitati, setFacilitati] = useState([]);
  const [hotelEmail, setHotelEmail] = useState("");
  const [hotelName, setHotelName] = useState("");

  useEffect(() => {
    setRoomData(props.roomData);
    setHotelEmail(props.hotelEmail);
    setHotelName(props.hotelName);
    let facCollector = [];
    props.roomData &&
      props.roomData.facilitati &&
      props.roomData.facilitati.forEach((fac) => facCollector.push(fac));
    setFacilitati(facCollector);
  }, [props]);
  // console.log(facilitati);

  const [inDateState, setInDateState] = useState(
    Math.floor(moment().valueOf() / 1000)
  );
  const [outDateState, setOutDateState] = useState(
    Math.floor(moment().valueOf() / 1000 + 86400)
  );
  const [nume, setNume] = useState("");
  const [numeError, setNumeError] = useState(false);
  const [persoane, setPersoane] = useState("");
  const [persoaneError, setPersoaneError] = useState(false);
  const [persoaneTouched, setPersoaneTouched] = useState(false);
  const [email, setEmail] = useState("");
  // const [emailError, setEmailError] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [mentiuni, setMentiuni] = useState("");
  const [politici, setPolitici] = useState(false);
  const [politiciError, setPoliticiError] = useState(false);

  useEffect(() => {
    nume.length > 0 ? setNumeError(false) : setNumeError(true);
  }, [nume]);

  useEffect(() => {
    persoane.length > 0 ? setPersoaneError(false) : setPersoaneError(true);
  }, [persoane]);

  useEffect(() => {
    phone.length > 0 ? setPhoneError(false) : setPhoneError(true);
  }, [phone]);

  useEffect(() => {
    politici === true ? setPoliticiError(false) : setPoliticiError(true);
  }, [politici]);

  const handleSubmit = async () => {
    let obj = {};
    obj.nume = nume;
    obj.persoane = persoane;
    obj.phone = phone;
    obj.email = email;
    obj.camera = roomData.nume;
    obj.inDate = moment.unix(inDateState).format("DD/MM/YYYY");
    obj.outDate = moment.unix(outDateState).format("DD/MM/YYYY");
    obj.emailHotel = hotelEmail;
    obj.hotel = hotelName;
    obj.mentiuni = mentiuni;

    console.log(numeError, persoaneError, phoneError, politiciError);
    if (
      numeError === false &&
      persoaneError === false &&
      phoneError === false &&
      politiciError === false
    ) {
      await db
        .collection("rezervari")
        .add(obj)
        .then(() =>
          history.push(props.lang === "RO" ? "/confirmare" : "/en/confirmation")
        );
    } else {
      alert("Vă rugăm completați toate câmpurile necesare!");
    }
  };

  return (
    <div className="section-standard">
      <div className="container-std first">
        <h1 id="testelement" className="room-title">
          {props.lang === "RO" ? "Rezervare nouă" : "New reservation"}
        </h1>
        <p className="paragraph-standard">
          {props.lang === "RO"
            ? "Vă rugăm completați formularul de mai jos pentru a rezerva această cameră. Câmpurile marcate cu roșu și asterisc (*)  sunt obligatorii."
            : "Please fill in the form below to book this room. Fields marked with an aseterisk (*) are required."}
        </p>
        {props.lang === "RO" &&
          data.camere.oferte
            .filter((el) => el.type === "simple")
            .map((el) => (
              <div>
                <p className="paragraph-standard" style={{ fontWeight: 700 }}>
                  <strong style={{ color: "Crimson" }}>{el.part1}</strong> -{" "}
                  <span>{el.part2}</span>
                </p>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              </div>
            ))}

        {props.lang === "RO" &&
          data.camere.oferte
            .filter((el) => el.type !== "simple")
            .map((el) => (
              <div>
                <p className="paragraph-standard" style={{ fontWeight: 700 }}>
                  <strong style={{ color: "Crimson" }}>{el.part1}</strong> -{" "}
                  <span>{el.part2}</span>
                </p>
                {el.part3.map((itm) => (
                  <p className="paragraph-standard" style={{ fontWeight: 400 }}>
                    • <strong>{itm.camera}</strong> - <span>{itm.pret}</span>
                  </p>
                ))}
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              </div>
            ))}

        {props.lang === "EN" &&
          data.camere.oferte_en
            .filter((el) => el.type === "simple")
            .map((el) => (
              <div>
                <p className="paragraph-standard" style={{ fontWeight: 700 }}>
                  <strong style={{ color: "Crimson" }}>{el.part1}</strong> -{" "}
                  <span>{el.part2}</span>
                </p>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              </div>
            ))}

        {props.lang === "EN" &&
          data.camere.oferte_en
            .filter((el) => el.type !== "simple")
            .map((el) => (
              <div>
                <p className="paragraph-standard" style={{ fontWeight: 700 }}>
                  <strong style={{ color: "Crimson" }}>{el.part1}</strong> -{" "}
                  <span>{el.part2}</span>
                </p>
                {el.part3.map((itm) => (
                  <p className="paragraph-standard" style={{ fontWeight: 400 }}>
                    • <strong>{itm.camera}</strong> - <span>{itm.pret}</span>
                  </p>
                ))}
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              </div>
            ))}
        <div className="card horizontal">
          {roomData && roomData.foto && (
            <div
              className="hz-image"
              style={{ backgroundImage: `url(${roomData.foto[0]})` }}
            ></div>
          )}

          <div className="card-content-holder">
            <p className="card-title">
              {roomData &&
                roomData.nume &&
                (props.lang === "RO" ? roomData.nume : roomData.nume_en)}
            </p>

            {roomData.pret &&
              roomData.pret.map((el) => (
                <div className="stars-holder w-embed">
                  {Array(el.capacitate)
                    .fill()
                    .map((el) => (
                      <img
                        key={Math.random()}
                        src="/images/app_img/person.svg"
                        style={{ height: 16 }}
                        alt=""
                      />
                    ))}
                  <p className="in-card-p">
                    {el.pret} / {props.lang === "RO" ? "noapte" : "night"}
                    {el.wEnd && " (weekend)"}
                  </p>
                </div>
              ))}

            <p className="paragraph-standard">
              <strong>
                {props.lang === "RO" ? "Facilități" : "Amenities"}:
              </strong>
               
              {facilitati !== [] &&
                facilitati
                  .map((itm) => (props.lang === "RO" ? itm.nume : itm.nume_en))
                  .join(", ")}
            </p>
          </div>
        </div>

        <MuiThemeProvider theme={theme}>
          <Dates
            queryIn={inDateState}
            queryOut={outDateState}
            setInDateState={setInDateState}
            setOutDateState={setOutDateState}
          />
          <TextField
            id="name"
            required
            type="text"
            error={numeError}
            fullWidth
            label={props.lang === "RO" ? "Nume Complet" : "Full name"}
            value={nume}
            onChange={(event) => setNume(event.target.value)}
            variant="outlined"
            style={{ marginTop: 20, marginBottom: 10, zIndex: 0 }}
            // helperText="Câmp necesar."
          />
          <TextField
            id="nr-pers"
            type="number"
            error={persoaneError}
            required
            fullWidth
            label={props.lang === "RO" ? "Nr. oaspeți" : "Number of guests"}
            value={persoane}
            onChange={(event) => setPersoane(event.target.value)}
            variant="outlined"
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <TextField
            id="phone"
            type="text"
            error={phoneError}
            required
            fullWidth
            label={props.lang === "RO" ? "Telefon" : "Phone"}
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
            variant="outlined"
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <TextField
            id="email"
            type="email"
            fullWidth
            label="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            variant="outlined"
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <TextField
            id="mentiuni"
            type="text"
            fullWidth
            label={
              props.lang === "RO" ? "Mențiuni speciale" : "Other instructions"
            }
            value={mentiuni}
            onChange={(event) => setMentiuni(event.target.value)}
            variant="outlined"
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <p className="paragraph-standard" style={{ marginTop: 20 }}>
            <strong>
              {props.lang === "RO"
                ? "Politici de rezervare"
                : "Reservation policies"}
            </strong>
          </p>

          {props.lang === "RO" ? (
            <p className="paragraph-standard" style={{ marginTop: 0 }}>
              Vă rugăm citiți{" "}
              <a target="_blank" href="/politici-rezervare/">
                <u>politicile de rezervare</u>
              </a>{" "}
              înainte de a trimite acest formular.
            </p>
          ) : (
            <p className="paragraph-standard" style={{ marginTop: 0 }}>
              Please read the{" "}
              <a target="_blank" href="/en/reservation-policies/">
                <u>reservation policies</u>
              </a>{" "}
              before submitting this form.
            </p>
          )}
          <FormControl error={politiciError}>
            <FormControlLabel
              style={{ marginTop: 10, marginBottom: 0 }}
              control={
                <Checkbox
                  checked={politici}
                  onChange={() => setPolitici(!politici)}
                  value="politici"
                  color="primary"
                />
              }
              label={
                props.lang === "RO"
                  ? "Sunt de acord cu politicile de rezervare"
                  : "I accept the reservation policies"
              }
            />
            {politiciError && (
              <FormHelperText>
                {props.lang === "RO" ? "Câmp necesar" : "Required field"}
              </FormHelperText>
            )}
          </FormControl>
        </MuiThemeProvider>
        <div
          className="button-inline w-button"
          style={{ marginTop: 20, padding: 15, marginRight: 0 }}
          onClick={handleSubmit}
        >
          Trimite
        </div>
        <p className="paragraph-standard" style={{ marginTop: 30 }}>
          <strong>
            {props.lang === "RO"
              ? "Procesarea datelor personale"
              : "Personal data processing notice"}
          </strong>
        </p>
        <p className="paragraph-standard" style={{ marginBottom: 20 }}>
          {props.gdpr}
        </p>
      </div>
    </div>
  );
}
