import React from "react";

export default function Footer(props) {
  return (
    <div className="footer">
      <div className="container w-container">
        <img
          src={props.logo}
          alt=""
          className="logo-footer"
          style={{ maxWidth: "40%" }}
        />

        {(props.fb !== null || props.ig !== null || props.yt !== null) && (
          <div className="social-container w-clearfix">
            {props.fb !== "" && props.fb !== null && (
              <a
                href={props.fb}
                target="_blank"
                rel="noopener noreferrer"
                className="social-footer-icon w-inline-block"
              >
                <img
                  src="/images/app_img/fb-icon-round.png"
                  alt=""
                  className="footer-icon"
                />
              </a>
            )}
            {props.ig !== null && props.ig !== "" && (
              <a
                href={props.ig}
                target="_blank"
                rel="noopener noreferrer"
                className="social-footer-icon w-inline-block"
              >
                <img
                  src="/images/app_img/insta.png"
                  alt=""
                  className="footer-icon"
                />
              </a>
            )}
            {props.yt !== null && props.yt !== "" && (
              <a
                href={props.yt}
                target="_blank"
                rel="noopener noreferrer"
                className="social-footer-icon w-inline-block"
              >
                <img
                  src="/images/app_img/youtube.png"
                  alt=""
                  className="footer-icon"
                />
              </a>
            )}
          </div>
        )}

        <p className="text-footer">
          © {new Date().getFullYear()} {props.nume}
        </p>
        <div className="addictad-holder">
          <p className="text-addictad">Design:</p>
          <a
            href="https://addictad.ro"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/app_img/addictad-alb.png"
              alt=""
              className="logo-addictad"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
