import React from "react";
import { Link } from "react-router-dom";

export default function CamereList(props) {
  return (
    <div className="card-holder">
      {props.camere.units
        .filter((item) => item.roomId !== props.exclude)
        .map((item) => (
          <Link
            key={Math.random()}
            className="card w-inline-block"
            to={
              props.lang === "RO"
                ? `/camere/${item.roomId}`
                : `/en/rooms/${item.roomId}`
            }
          >
            <div
              className="card-image"
              style={{ backgroundImage: `url(${item.foto[0]})` }}
            ></div>
            <div className="card-content-holder">
              <p className="card-title">
                {props.lang === "RO" ? item.nume : item.nume_en}
              </p>
              {item.pret.map((el) => (
                <div key={Math.random()} className="stars-holder w-embed">
                  {Array(el.capacitate)
                    .fill()
                    .map((el) => (
                      <img
                        key={Math.random()}
                        src="/images/app_img/person.svg"
                        style={{ height: 16 }}
                        alt=""
                      />
                    ))}
                  {el.copii &&
                    Array(el.copii)
                      .fill()
                      .map((el) => (
                        <img
                          key={Math.random()}
                          src="/images/app_img/person.svg"
                          style={{ height: 10 }}
                          alt=""
                        />
                      ))}
                  <p className="in-card-p">
                    {el.pret} / {props.lang === "RO" ? "noapte" : "night"}{" "}
                    {el.wEnd && " (weekend)"}
                  </p>
                </div>
              ))}

              <p className="room-details">
                {props.lang === "RO" ? item.descriere : item.descriere_en}
              </p>
              <p className="button-standard-100">
                {props.lang === "RO"
                  ? "detalii & rezervări"
                  : "details & reservations"}
              </p>
            </div>
          </Link>
        ))}

      <div className="swiper-end"></div>
    </div>
  );
}
